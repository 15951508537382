import { showNotification } from '@mantine/notifications';
import { createContext, useContext, useEffect, useState } from 'react';
import { firebaseClient } from '../firebase/client';

const AuthContext = createContext<{
    user: firebaseClient.User | null;
    isAuthInProgress?: boolean;
    isAuthorized?: boolean;
}>({
    user: null,
});

export function AuthProvider({ children }: any) {
    const [user, setUser] = useState<firebaseClient.User | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        firebaseClient.auth().onAuthStateChanged((user) => {
            setUser(user);
            setIsLoading(false);
        });
    }, []);

    // force refresh the token every 10 minutes
    useEffect(() => {
        const handle = setInterval(async () => {
            console.log(`refreshing token...`);
            const user = firebaseClient.auth().currentUser;
            try {
                if (user) await user.getIdToken(true);
            } catch (e: any) {
                showNotification({
                    color: 'red',
                    message: e?.message || 'Unable to refresh token',
                });
            }
            
        }, 10 * 60 * 1000);
        return () => clearInterval(handle);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthInProgress: isLoading,
                isAuthorized: !!user && !isLoading,
            }}
        >
            {!isLoading && children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(AuthContext);
};
