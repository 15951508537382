import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const base = 'https://analytics.snipp.live';

const isProd = window.location.hostname.includes('snipp.live');

const instance = createInstance({
    urlBase: base,
    siteId: 1,
    trackerUrl: `${base}/matomo.php`, // optional, default value: `${urlBase}matomo.php`
    srcUrl: `${base}/matomo.js`, // optional, default value: `${urlBase}matomo.js`
    disabled: !isProd, // optional, false by default. Makes all tracking calls no-ops if set to true.
    heartBeat: {
        // optional, enabled by default
        active: true, // optional, default value: true
        seconds: 15, // optional, default value: `15
    },
    linkTracking: false, // optional, default value: true
    configurations: {
        // optional, default value: {}
        // any valid matomo configuration, all below are optional
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
    },
});

export { instance as matomoInstance, MatomoProvider };
