interface EmailProps {
    subject?: string;
    body?: string;
}

const contactEmailAddress = 'snipp.live@yevgeniy.xyz';

const composeEmail = ({
    subject = 'Snipp.live - feedback',
    body = '',
}: EmailProps): void => {
    window.open(
        `mailto:${contactEmailAddress}?subject=${subject}&body=${body}`
    );
};

const reportBug = ({
    userId,
    snippetId,
}: {
    userId?: string;
    snippetId?: string;
}) => {
    const subject = `[Bug report] - ${new Date().toISOString()}`;

    let body = `Browser: ${navigator.userAgent}, User id: ${userId}, Snippet id: ${snippetId}
    `;

    return composeEmail({ subject, body });
};

export { composeEmail, contactEmailAddress, reportBug };
