import React, { Suspense } from 'react';
import { Link } from 'react-router-dom';
import { LazyFallback } from '../common/components/LazyFallback';

const UserDropdown = React.lazy(() => import('./UserProfile/UserDropdown'));

const Header: React.FC = () => {
    return (
        <header className="header header__bg">
            <div className="header__container">
                <div className="header__logo">
                    <Link to="/">Snipp</Link>
                    <span className="header__domain">.live</span>
                </div>
                <div className="header__content">
                    <nav className="header__nav">
                        <ul>
                            <li className="header__li">
                                <Link
                                    className="header__nav-link nav__new-link"
                                    to={'/new'}
                                >
                                    New Snippet
                                </Link>
                            </li>
                            <li className="header__li">
                                <Link
                                    className="header__nav-link"
                                    to={'/recent'}
                                >
                                    Recent snippets
                                </Link>
                            </li>
                        </ul>
                    </nav>

                    <div className="header__user">
                        <Suspense fallback={<LazyFallback></LazyFallback>}>
                            <UserDropdown />
                        </Suspense>
                    </div>
                </div>
            </div>
        </header>
    );
};

export { Header };
