import React from 'react';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { createRoot } from 'react-dom/client';
import { matomoInstance, MatomoProvider } from './matomo/matomo';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './reset.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { UserInfoContainer } from './domain/hooks/useUserInfo';
import { Header } from './components/Header';
import { LazyFallback } from './common/components/LazyFallback';
import { AuthProvider } from './providers/auth';
import Landing from './routes/landing';
import { usePageTrack } from './matomo/usePageTrack';

const New = React.lazy(() => import('./routes/new'));
const Recent = React.lazy(() => import('./routes/recent'));
const Settings = React.lazy(() => import('./routes/settings'));
const Snippet = React.lazy(() => import('./routes/snippet'));
const Privacy = React.lazy(() => import('./routes/privacy'));

Bugsnag.start({
    apiKey: 'cf98e6f535961f7270cfba6771a7b9a7',
    plugins: [new BugsnagPluginReact()]
  })

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React)

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

const App: React.FC = () => {
    usePageTrack();
    
    return (
        <Routes>
            <Route
                path="/s/:snippetId"
                element={
                    <React.Suspense fallback={<LazyFallback></LazyFallback>}>
                        <Snippet />
                    </React.Suspense>
                }
            />
            <Route path="/" element={<Landing />} />
            <Route
                path="/new"
                element={
                    <React.Suspense fallback={<LazyFallback></LazyFallback>}>
                        <New />
                    </React.Suspense>
                }
            />
            <Route
                path="/settings"
                element={
                    <React.Suspense fallback={<LazyFallback></LazyFallback>}>
                        <Settings />
                    </React.Suspense>
                }
            />
            <Route
                path="/recent"
                element={
                    <React.Suspense fallback={<LazyFallback></LazyFallback>}>
                        <Recent />
                    </React.Suspense>
                }
            />
            <Route
                path="/privacy"
                element={
                    <React.Suspense fallback={<LazyFallback></LazyFallback>}>
                        <Privacy />
                    </React.Suspense>
                }
            />
        </Routes>
    );
};

root.render(
    <React.StrictMode>
        <ErrorBoundary>
            <BrowserRouter>
                <MatomoProvider value={matomoInstance}>
                    <MantineProvider
                        theme={{
                            colors: {
                                brand: [
                                    '#DBEFE8',
                                    '#BAE6D7',
                                    '#97E4CA',
                                    '#6FE9C0',
                                    '#60D6AE',
                                    '#55C29D',
                                    '#4EAD8D',
                                    '#4F937C',
                                    '#4D7E6E',
                                    '#4A6D61',
                                ],
                            },
                            colorScheme: 'dark',
                            primaryColor: 'teal',
                        }}
                    >
                        <NotificationsProvider>
                            <AuthProvider>
                                <UserInfoContainer.Provider>
                                    <Header></Header>
                                    <App></App>
                                </UserInfoContainer.Provider>
                            </AuthProvider>
                        </NotificationsProvider>
                    </MantineProvider>
                </MatomoProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>
);

reportWebVitals();
