import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="container footer__container">
                <div className="footer__copyright">
                    @2022 <a href="/">Snipp.live</a>
                    {'. '}
                    <Link to={'/privacy'}>Privacy policy</Link>
                </div>
                <div className="footer__author">
                    Made by{' '}
                    <a target="_blank" href="https://yevgeniy.xyz">
                        Yevgeniy M.
                    </a>
                </div>
                <div className="footer__special-thanks">
                    Powered by{' '}
                    <a target="_blank" href="https://firepad.io/">
                        Firepad
                    </a>
                    ,{' '}
                    <a
                        target="_blank"
                        href="https://microsoft.github.io/monaco-editor/"
                    >
                        Monaco Editor
                    </a>{' '}
                    and{' '}
                    <a target="_blank" href="https://firebase.google.com/">
                        Firebase
                    </a>
                </div>
            </div>
        </footer>
    );
};

export { Footer };
