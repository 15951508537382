const firebaseConfig = {
    apiKey: 'AIzaSyAs6T9atDrmRfTB7M7sPWjeS6BkwkcP-vk',
    authDomain: 'snippy-live.firebaseapp.com',
    databaseURL:
        'https://snippy-live-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'snippy-live',
    storageBucket: 'snippy-live.appspot.com',
    messagingSenderId: '723194504902',
    appId: '1:723194504902:web:fcb75f6a42ebffcda159c7',
};

export { firebaseConfig };
