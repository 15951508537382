import { useEffect } from 'react';
import { createContainer } from 'unstated-next';
import { useLocalStorage } from '../../common/hooks/useLocalStorage';
import { useAuth } from '../../providers/auth';
import { UserInfo } from '../UserInfo';

export function useUserInfo() {
    const { user, isAuthInProgress } = useAuth();
    const [userInfoCache, setUserInfoCache] = useLocalStorage<UserInfo>(
        'userInfo',
        {
            name: '',
        } as UserInfo
    );

    const setUserInfoByName = (name: string) => {
        setUserInfoCache({ name });
    };

    useEffect(() => {
        if (user?.displayName) {
            setUserInfoByName(user.displayName);
        }
    }, [user?.displayName]);

    return {
        name: user && user.displayName ? user.displayName : userInfoCache.name,
        user,
        isAnonymous: user?.isAnonymous,
        setUserInfoByName,
    } as const;
}

export const UserInfoContainer = createContainer(useUserInfo);
