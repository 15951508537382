import {
    Code,
    List,
    Space,
    Text,
    Title,
    Highlight,
    MantineTheme,
} from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { contactEmailAddress } from '../common/utils/composeEmail';

const Landing: React.FC = () => {
    const highLightStyles = (theme: MantineTheme) => ({
        backgroundImage: theme.fn.linearGradient(
            45,
            theme.colors.cyan[5],
            theme.colors.indigo[5]
        ),
        fontWeight: 700,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
    });

    return (
        <>
            <section className="landing container">
                <Title order={1}>
                    <Highlight
                        highlightStyles={(theme) => highLightStyles(theme)}
                        highlight="Snipp.live"
                    >
                        👋 Welcome to Snipp.live – live, collaborative
                    </Highlight>{' '}
                    <Code style={{ fontSize: '34px' }}>code editing</Code>{' '}
                    platform 💻 🚀
                </Title>
                <Space h="md" />
                <Text size={'xl'}>
                    From the name you can (somehow) understand this project is
                    for coding snippets. Means you 🛑 <strong>can't</strong> 🛑
                    really build & run stuff here, but you can write a code
                    snippet with some other people in realtime. Let me elaborate
                    on those adjectives from the top:
                </Text>
                <Space h="xl" />
                <Title order={2}>🚨 Live</Title>
                <Space h="md" />
                <Text size={'xl'}>
                    Whenever you change the code, its get updated and save into
                    realtime database.
                </Text>
                <Text size={'xl'}>On top of that 👇</Text>
                <List size="xl">
                    <List.Item>
                        ❗️ Live movement of the pointer for each user
                    </List.Item>
                    <List.Item>
                        👨🏼‍✈️ Name of the person how is doing changes
                    </List.Item>
                </List>
                <Space h="xl" />
                <Title order={2}>🔥 Collaborative</Title>
                <Space h="md" />
                <Text size={'lg'}>
                    <Highlight
                        highlightStyles={(theme) => highLightStyles(theme)}
                        highlight="snippet"
                    >
                        This is the main feature and purpose of this project.
                        You can share your snippet with other people by just
                        sending them a link.
                    </Highlight>
                    <Space h="md" />
                    <Text>
                        👉 💨 They can join it <strong>right away</strong>.
                    </Text>
                    <Text weight={'bold'}>👉 🌈 No auth required</Text>
                </Text>
                <Space h="xl" />
                <Title order={2}>👨🏾‍💻 Code Editing</Title>
                <Space h="md" />
                <List size="xl">
                    <List.Item>🚫 💸 Free to use</List.Item>
                    <List.Item>
                        🔌 <strong>Reliable</strong> – powered by{' '}
                        <a target="_blank" href="https://firepad.io/">
                            Firepad
                        </a>
                        ,{' '}
                        <a
                            target="_blank"
                            href="https://microsoft.github.io/monaco-editor/"
                        >
                            Monaco Editor
                        </a>{' '}
                        and{' '}
                        <a target="_blank" href="https://firebase.google.com/">
                            Firebase
                        </a>
                    </List.Item>
                    <List.Item>🦄 Auth-free, Cookies-free by default</List.Item>
                    <List.Item>
                        😲{' '}
                        <a
                            target="_blank"
                            href="https://code.visualstudio.com/docs/editor/intellisense"
                        >
                            IntelliSense
                        </a>{' '}
                        support for{' '}
                        <Code>Javascript, Typescript, HTML, CSS</Code>
                    </List.Item>
                </List>
                <Space h="xl" />
                <Title order={1} align={'center'}>
                    Get started by creating a
                    <Link to={'/new'}>
                        <Highlight
                            highlightStyles={(theme) => highLightStyles(theme)}
                            highlight="NEW SNIPPET"
                        >
                            NEW SNIPPET
                        </Highlight>
                    </Link>
                </Title>

                <Space h="xl" />
                <Title order={2}>Supported syntax/languages</Title>
                <Space h="md" />
                <List size="lg">
                    <List.Item>Javascript, Typescript</List.Item>
                    <List.Item>Python</List.Item>
                    <List.Item>Java</List.Item>
                    <List.Item>Kotlin</List.Item>
                    <List.Item>Go</List.Item>
                    <List.Item>C, C++</List.Item>
                    <List.Item>C#</List.Item>
                    <List.Item>HTML, CSS</List.Item>
                </List>
                <Space h="xl" />
                <Title order={2}>Target audience</Title>
                <Space h="md" />

                <List size="lg">
                    <List.Item>Tech interviewers</List.Item>
                    <List.Item>
                        Group of people who want to pair program
                    </List.Item>
                </List>

                <Space h="lg" />
                <Space h="lg" />
                <Text size="lg">
                    Your feedback is deeply appreciated, if you have some
                    ideas/bug reports/opinion – take a time and fire me an email
                    to ✉️{' '}
                    <a target="_blank" href={`mailto:${contactEmailAddress}`}>
                        {contactEmailAddress}
                    </a>
                </Text>
            </section>
            <Footer></Footer>
        </>
    );
};

export default Landing;
