import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const usePageTrack = () => {
    const { trackPageView } = useMatomo();
    const { pathname } = useLocation();
    useEffect(() => {
        trackPageView({ href: pathname });
    }, [pathname]);
};

export { usePageTrack };
